import {useState} from 'react'
import { Link } from 'react-router-dom'
import './forms.css'
import {sendPasswordResetEmail} from 'firebase/auth'
import {auth} from './firebase'

export default function ForgotPassword(){
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const login = async e => {
    e.preventDefault()
    try {
        await sendPasswordResetEmail(auth, email)
        setError("Check your inbox for further instructions.")
      } catch {
        setError("Failed to reset password.")
      }
  }

  return(
    <div className="landing-splash">
      <div className="waves"></div>
      <div className='center'>
        <div className="logo">
          <Link to="/"><img src="./img/logo.svg" /></Link>
        </div>

        {error && <div className='auth__error'>{error}</div>}
        <div className='auth box'>
          <h1>Password Reset</h1>
          <form onSubmit={login} name='login_form'>
            <input 
              type='email' 
              value={email}
              required
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}/>

            <button type='submit'>Reset Password</button>
          </form>
          <p>
            <Link to='/login'>Login</Link>
          </p>
        </div>
        <div className='bottom-area'>
          <div className="spacer"></div>
        </div>
      </div>
    </div>
  )
}