import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocCannyEdge() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    ControlNet
                </h1>
                <h2>Canny Edge</h2>
                <p>Canny Edge ControlNet is a monochrome image with white edges on a black background. You can generate this image from a regular photograph or any other picture by applying a preprocess algorithm to it. The StableNetFusion app will do this automatically for you.</p>

                <p>Canny Edge is a great way to insert an object that you have a photograph of into an AI generated image.
                </p>
            </div>
        </div>
    )
}