import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocScribble() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    ControlNet
                </h1>
                <h2>Scribble</h2>
                <p>Scribble ControlNet is a hand-drawn monochrome image. It is trained on human scribbles, and as such, works best in the form of outlines of things and objects and without fill.</p>
                <p>The StableNetFusion app has drawing tools such as pencil and eraser of varied sizes for you to sketch your Scribble image.</p>
            </div>
        </div>
    )
}