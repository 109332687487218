import { Link } from 'react-router-dom'
import './LandingPage.css'

export default function LandingPage() {
    return (
        <div className="webpage">
            <div className="landing-splash">
                <div className="waves"></div>
                <div className="heading">
                    <img src="./img/logo.svg" />
                    <div className="name">StableNetFusion</div>
                    <div className="nav">
                        <Link className="nav-link" to='/documentation'>Docs</Link>
                        <Link className="nav-link" to='/login'>Login</Link>
                        <Link className="nav-button" to='/register'>Sign Up</Link>
                    </div>
                </div>

                <div className="product">
                    <div className="product-img"></div>
                </div>  
                <div className="splash-text">
                    Easily create AI art, images, and photography with the latest technologies of Stable&nbsp;Diffusion and ControlNet.
                    <a href="https://u.pcloud.link/publink/show?code=XZOPYGVZdtyOR1lVf5XscutHD9gWEb4wtL6X" className="download" target="_blank">Download Beta 0.1.0<span>NVIDIA required</span></a>
                </div> 
            </div>
            <div className="landing-body">
                <h1>Unlock the power of the latest A.I. image generation model Stable&nbsp;Diffusion&nbsp;XL (SDXL) to create your beautiful art.</h1>
                
                <div className="section"> 
                    <div className="section-img"
                        style={{backgroundImage: "url(./img/intro-art-010.png)", backgroundPosition: "center right"}}
                    ></div>
                    <div className="section-text">
                        <p>The StableNetFusion application will give you access to the latest Stable Diffusion text-to-image, image-to-image, and inpainting models.</p>
                        <p>You can toggle between Stable Diffusion 1.5 and Stable Diffusion XL. The latter is a cutting edge model capable of generating realistic faces, legible text within the images, and better image composition, all while using shorter and simpler prompts.</p>
                        
                    </div>
                </div>
            
                <h1>Have full control over your images using Scribbles, Semantic Segmentation, Canny Edge, and many more ControlNets</h1>

                
                <div className="section" style={{"flexDirection": "row-reverse"}}> 
                    <div className="section-img"
                        style={{backgroundImage: "url(./img/intro-art-008.png)"}}
                    ></div>
                    <div className="section-text">
                        <p>ControlNet allows you to sketch, draw, and create collages that will influence the AI generated image.</p>

                        <p>And StableNetFusion has a set of easy to use drawing tools within the app for you to create ControlNet so you won’t have to use a separate image editor. Everything you need to create and edit your AI art is readily available to you.
                        </p>
                    </div>
                </div>

                    
                <h1>Easy installation and easy to use. There is no need manually configure and install AI models, everything is available to you with one click.</h1>
                <div className="section"> 
                    <div className="section-img"
                        style={{backgroundImage: "url(./img/flowers.png)"}}
                    ></div>
                    <div className="section-text">
                        <p>StableNetFusion is a one stop shop for all your AI image generating needs. </p>
                        <p>You do not need to know how to use GitHub to set up StableNetFusion. There is no multi-steps installation process. You do not have to manually find and configure various AI models. 
                        </p>
                        <p>StableNetFusion just works out-of-the-box, so you can focus on creating your art.</p>
                    </div>
                </div>

                    <h1>Design for artists, making AI art accessible to everyone.</h1>
                
            </div>
        </div>
    )
}