import './profile.css'
import { useState } from "react"
import {useAuthValue} from './AuthContext'
import { signOut, deleteUser } from 'firebase/auth' 
import { auth } from './firebase'
import {useHistory} from 'react-router-dom'

function Profile() {
  const {currentUser} = useAuthValue()
  const history = useHistory()
  const [error, setError] = useState('')

  const deleteAccount = () => {
    if (window.confirm("Do you want to delete your account?")) {
      deleteUser(currentUser).then(() => {
        history.push('/login')
        window.location.href = "stablenetfusion://logout"
      }).catch((error) => {
        setError(error.message)
      });
    } 
  }

  return (
    <div className="landing-splash">
      <div className="waves"></div>
      <div className='center'>
       {error && <div className='auth__error'>{error}</div>}
        <div className='profile box'>
          <div className="center-flex">
            <div className="profile-pic">
              {Array.from(currentUser?.email)[0].toUpperCase()}
            </div>
          </div>
          <p>{currentUser?.email}</p>
          <p>
           Email verified
            {`${currentUser?.emailVerified ? " ✅" : " ❌"}`}
          </p>
          <span className="button" onClick={() => signOut(auth)}>Sign Out</span>
          <span onClick={deleteAccount}>Delete Account</span>
        </div>
      </div>
    </div>
  )
}

export default Profile