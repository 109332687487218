import {useState} from 'react'
import './forms.css'
import {auth} from './firebase'
import {useHistory, Link} from 'react-router-dom'
import {createUserWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {useAuthValue} from './AuthContext'

export default function Register() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const history = useHistory()
  const {setTimeActive} = useAuthValue()

  const validatePassword = () => {
    let isValid = true
    if (password !== '' && confirmPassword !== ''){
      if (password !== confirmPassword) {
        isValid = false
        setError('Passwords does not match.')
      }
    }
    return isValid
  }

  const register = e => {
    e.preventDefault()
    setError('')
    if(validatePassword()) {
      // Create a new user with email and password using firebase
        createUserWithEmailAndPassword(auth, email, password)
        .then(() => {
          sendEmailVerification(auth.currentUser)
          .then(() => {
            setTimeActive(true)
            history.push('/verify-email')
          }).catch((err) => alert(err.message))
        })
        .catch(err => {
          let errMsg = err.message
          if (errMsg === "Firebase: Password should be at least 6 characters (auth/weak-password).")
            errMsg = "Password should be at least 6 characters."

          if (errMsg === "Firebase: Error (auth/email-already-in-use).")
            errMsg = "Email already in use."
    
          setError(errMsg)
        })
    }
    setEmail('')
    setPassword('')
    setConfirmPassword('')
  }

  return (
    <div className="landing-splash">
      <div className="waves"></div>
        <div className='center'>
          <div className="logo">
            <Link to="/"><img src="./img/logo.svg" /></Link>
          </div>
          {error && <div className='auth__error'>{error}</div>}
          <div className='auth box'>
            <h1>Register</h1>
            
            <form onSubmit={register} name='registration_form'>
              <input 
                type='email' 
                value={email}
                placeholder="Email"
                required
                onChange={e => setEmail(e.target.value)}/>

              <input 
                type='password'
                value={password} 
                required
                placeholder='Password'
                onChange={e => setPassword(e.target.value)}/>

                <input 
                type='password'
                value={confirmPassword} 
                required
                placeholder='Confirm password'
                onChange={e => setConfirmPassword(e.target.value)}/>

              <button type='submit'>Register</button>
            </form>
            <p style={{marginBottom: "8px", lineHeight: "16px"}}>
              By clicking "Register" you agree to the <Link to="/terms-conditions">Terms and Conditions</Link> and <Link to="/privacy-policy">Privacy&nbsp;Policy.</Link>
            </p>
            <p>
              Already have an account? <Link to='/login'>Login</Link>
            </p>
          </div>
          <div className='bottom-area'>
            <div className="spacer"></div>
          </div>
        </div>
      </div>
  )
}
