import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocImageToImage() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    Stable Diffusion
                </h1>
                <h2>Image-To-Image</h2>
                <p>Image-to-image is a Stable Diffusion task that allows you to submit an existing image as the starting point of the diffusion process. Noise is then added to the initial image, and given a text prompt, the end result generated new image will resemble the original image in colors and feel.</p>

                <p>The StableNetFusion app has tools that allow you to easily compose your initial image. You can make a collage, and resize and reposition your images as you wish before using it in the image-to-image process.
                </p>
            </div>
        </div>
    )
}