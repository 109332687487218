import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocInpainting() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    Stable Diffusion
                </h1>
                <h2>Inpainting & Outpainting</h2>
                <p>Inpainting is a Stable Diffusion task that replaces or edits specific areas of an image. You can prompt Stable Diffusion to generate entirely new content in an existing image, or touch-up and alter the image as needed.</p>

                <p>Conversely, outpainting is the same task as inpainting but applied outside of the boundaries of the original image. Outpainting will generate new content that seamlessly extends the initial image based on the prompt.
                </p>
                <p>
                    The StableNetFusion app has a set of drawing and erasing tools that will let you quickly create inpainting areas on-the-fly.
                </p>
            </div>
        </div>
    )
}