import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocDepthMap() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    ControlNet
                </h1>
                <h2>Depth Map</h2>
                <p>Depth Map ControlNet is a grayscale image with black representing deep areas and white representing shallow areas. You can generate this image from a regular photograph, or any other picture, by applying a preprocess algorithm to it. The StableNetFusion app will do this automatically for you.</p>
                <p>Depth Map is a great way for you to alter existing photographs by changing the details in it while preserving the general composition of the image.</p>
            </div>
        </div>
    )
}