import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocInstallation() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    StableNetFusion
                </h1>
                <h2>Installation</h2>
                <p>
                    Installing StableNetFusion is simple:
                </p>
                <ul className="instructions">
                    <li>Download the zip file via <a href="https://u.pcloud.link/publink/show?code=XZOPYGVZdtyOR1lVf5XscutHD9gWEb4wtL6X" target="_blank">this link</a>.</li>
                    <li>Unzip the app by extracting it from the zip file.</li>
                    <li>Double click the app to install on your machine. <span>(you might have to bypass Windows’ warning popup by selecting the “Install anyway” option)</span></li>

                </ul>
                <p>
                    That’s it! And now you can open the StableNetFusion app and create a login with your email and start generating your AI images.
                </p>
            </div>
        </div>
    )
}