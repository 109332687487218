import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import Profile from './Profile'
import Register from './Register'
import VerifyEmail from './VerifyEmail';
import Login from './Login'
import {AuthProvider} from './AuthContext'
import {useState, useEffect} from 'react'
import {auth} from './firebase'
import {onAuthStateChanged} from 'firebase/auth'
import PrivateRoute from './PrivateRoute'
import ForgotPassword from './ForgotPassword'
import LandingPage from './LandingPage'
import PrivacyPolicy from "./PrivacyPolicy"
import TermsAndConditions from './TermsAndConditions';

import DocStableNetFusion from './Documentation/DocStableNetFusion';
import DocInstallation from './Documentation/DocInstallation';
import DocStableDiffusion from './Documentation/DocStableDiffusion';
import DocSDXL from './Documentation/DocSDXL';
import DocInpainting from './Documentation/DocInpainting';
import DocTextToImage from './Documentation/DocTextToImage';
import DocImageToImage from './Documentation/DocImageToImage';
import DocCannyEdge from './Documentation/DocCannyEdge';
import DocControlNet from './Documentation/DocControlNet';
import DocDepthMap from './Documentation/DocDepthMap';
import DocScribble from './Documentation/DocScribble';
import DocSegmentation from './Documentation/DocSegmentation';


function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)
  
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
     })
  }, [])

  return (
    <Router>
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <Switch>
          <PrivateRoute exact path="/profile" component={Profile} />
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path='/verify-email' component={VerifyEmail} /> 
          <Route exact path='/forgot-password' component={ForgotPassword} /> 
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-conditions" component={TermsAndConditions} />
        
          <Route exact path="/documentation" component={DocStableNetFusion} />
          <Route exact path="/documentation/stablenetfusion" component={DocStableNetFusion} />
          <Route exact path="/documentation/stablenetfusion/installation" component={DocInstallation} />
          <Route exact path="/documentation/stablediffusion" component={DocStableDiffusion} />
          <Route exact path="/documentation/stablediffusion/SDXL" component={DocSDXL} />
          <Route exact path="/documentation/stablediffusion/text-to-image" component={DocTextToImage} />
          <Route exact path="/documentation/stablediffusion/image-to-image" component={DocImageToImage} />
          <Route exact path="/documentation/stablediffusion/inpainting-outpainting" component={DocInpainting} />
          <Route exact path="/documentation/controlnet" component={DocControlNet} />
          <Route exact path="/documentation/controlnet/scribble" component={DocScribble} />
          <Route exact path="/documentation/controlnet/canny-edge" component={DocCannyEdge} />
          <Route exact path="/documentation/controlnet/semantic-segmentation" component={DocSegmentation} />
          <Route exact path="/documentation/controlnet/depth-map" component={DocDepthMap} />

        </Switch>
        </AuthProvider>
  </Router>
  );
}

export default App;
