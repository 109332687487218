import { Link, useLocation } from 'react-router-dom'

function SideNavLink ({location, label, currentLocation}) {
    return (
        <span className={`${location === currentLocation ? "active" : ""}`}>
            <Link to={location}>
                {label}
            </Link>
        </span>
    )
}

export default function DocSidePanel() {
    const currentRoute = useLocation()
    return (
        <div className="doc-sidepanel">
            <div className="doc-logo">
                <Link to='/'><img src="/img/logo.svg" /></Link>
            </div>
            <div className='topics'>
                <div className="topic">
                    <div className="topic-title">
                        <SideNavLink 
                            location={'/documentation/stablenetfusion'}
                            label={"StableNetFusion"}
                            currentLocation={currentRoute.pathname}
                        />
                    </div>
                    <ul>
                        <li>
                            <SideNavLink 
                                location={'/documentation/stablenetfusion/installation'}
                                label={"Installation"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                    </ul>
                </div>
                <div className="topic">
                    <div className="topic-title">
                        <SideNavLink 
                            location={'/documentation/stablediffusion'}
                            label={"Stable Diffusion"}
                            currentLocation={currentRoute.pathname}
                        />
                    </div>
                    <ul>
                        <li>
                            <SideNavLink 
                                location={'/documentation/stablediffusion/SDXL'}
                                label={"SDXL vs. SD 1.5"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/stablediffusion/text-to-image'}
                                label={"Text to Image"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/stablediffusion/image-to-image'}
                                label={"Image to Image"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/stablediffusion/inpainting-outpainting'}
                                label={"Inpainting & Outpainting"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                    </ul>
                </div>
                <div className="topic">
                    <div className="topic-title">
                        <SideNavLink 
                            location={'/documentation/controlnet'}
                            label={"ControlNet"}
                            currentLocation={currentRoute.pathname}
                        />
                    </div>
                    <ul>
                        <li>
                            <SideNavLink 
                                location={'/documentation/controlnet/scribble'}
                                label={"Scribble"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/controlnet/canny-edge'}
                                label={"Canny Edge"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/controlnet/semantic-segmentation'}
                                label={"Semantic Segmentation"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                        <li>
                            <SideNavLink 
                                location={'/documentation/controlnet/depth-map'}
                                label={"Depth Map"}
                                currentLocation={currentRoute.pathname}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}