import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocSDXL() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    Stable Diffusion
                </h1>
                <h2>Stable Diffusion XL vs. Stable Diffusion 1.5</h2>
                <p>The newest Stable Diffusion model is Stable Diffusion XL (SDXL) and it represents a big leap forward in terms of quality and image fidelity to the previous model Stable Diffusion 1.5.</p>
                <p>With SDXL you can generate high definition photo-realistic images comparable to that of MidJourney. And it can interpret shorter prompts accurately with fewer artifacts and deformities.</p>
                <p>The drawback with SDXL is that not many ControlNets are currently available for it, and those that are have not been extensively trained so their quality does suffer.</p>
                <p>The StableNetFusion app makes both SDXL and SD 1.5 available to you, so you can choose which model is the best fit for your use case.</p>
            </div>
        </div>
    )
}