import "./documentation.css"
import DocSidePanel from "./DocSidePanel"
import { Link } from "react-router-dom"

export default function DocStableDiffusion() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    Stable Diffusion
                </h1>
                <h2>About</h2>
                <p>
                    Stable Diffusion is the premiere open-sourced AI image generation model capable of generating high quality photo-realistic images, art and art styles, via text prompts. It is a latent diffusion model, a kind of deep generative artificial neural network.
                </p>
                <p>
                    Stable Diffusion can also perform <Link to='/documentation/stablediffusion/image-to-image'>image-to-image</Link>, <Link to='/documentation/stablediffusion/inpainting-outpainting'>inpainting and outpainting</Link> image generation guided by text prompts.
                </p>
            </div>
        </div>
    )
}