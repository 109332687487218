import {useState} from 'react'
import { Link } from 'react-router-dom'
import {signInWithEmailAndPassword, sendEmailVerification} from 'firebase/auth'
import {auth} from './firebase'
import {useHistory} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

export default function Login(){
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('') 
  const [error, setError] = useState('')
  const {setTimeActive} = useAuthValue()
  const history = useHistory()

  const login = e => {
    e.preventDefault()
    signInWithEmailAndPassword(auth, email, password)
    .then(() => {
      if(!auth.currentUser.emailVerified) {
        sendEmailVerification(auth.currentUser)
        .then(() => {
          setTimeActive(true)
          history.push('/verify-email')
        })
      .catch(err => alert(err.message))
      } else {
        history.push('/profile')
      }
    })
    .catch(err => {
      let errMsg = err.message
      if (errMsg === "Firebase: Error (auth/user-not-found).")
        errMsg = "User not found."

      if (errMsg === "Firebase: Error (auth/wrong-password).")
        errMsg = "Wrong password."
      setError(errMsg)
    })
  }

  return(
    <div className="landing-splash">
      <div className="waves"></div>
      <div className='center'>
        <div className="logo">
          <Link to="/"><img src="./img/logo.svg" /></Link>
        </div>
        {error && <div className='auth__error'>{error}</div>}
        <div className='auth box'>
          <h1>Login</h1>
          
          <form onSubmit={login} name='login_form'>
            <input 
              type='email' 
              value={email}
              required
              placeholder="Email"
              onChange={e => setEmail(e.target.value)}/>

            <input 
              type='password'
              value={password}
              required
              placeholder='Password'
              onChange={e => setPassword(e.target.value)}/>

            <button type='submit'>Login</button>
          </form>
          <p>
            <Link to='/forgot-password'>Forgot Password</Link>
          </p>
        </div>
        <div className='bottom-area'>
          <div className='btn-register-big'>
            <Link to="/register">Sign up for an Account</Link>
          </div>
        </div>
      </div>
    </div>
  )
}