import { initializeApp } from 'firebase/app'
import {getAuth} from 'firebase/auth'
import { getAnalytics } from "firebase/analytics"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1ULZxm4McLOpvPclN_46MEVZRrD1g0Ks",
  authDomain: "stablenetfusion.firebaseapp.com",
  projectId: "stablenetfusion",
  storageBucket: "stablenetfusion.appspot.com",
  messagingSenderId: "915568542440",
  appId: "1:915568542440:web:21eb059843f8485f60bf5d",
  measurementId: "G-7YKMH9YY6B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)

export {auth}