import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocStableNetFusion() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    StableNetFusion
                </h1>
                <h2>About</h2>
                <p>
                    StableNetFusion is a Windows application that runs on the Nvidia GPU. It is powered by the latest Stable Diffusion AI image generation and ControlNet technologies, and allows you to easily create AI images via text prompts, drawings, or photographs.
                </p>
                <p>
                    Our goal in creating StableNetFusion is to democratize access to AI image generation technologies, and to make it user-friendly for artists and non-techies alike. You don’t need to code, or know how to clone a repository, or manually locate and install various AI models. Everything is available to you out-of-the-box, and “just works!”
                </p>
                <p>
                    In addition, we created a set of drawing tools in StableNetFusion for you to sketch and paint ControlNet images so you can edit your AI generated images on-the-fly, without the need to use an image editing software like Photoshop.
                </p>
            </div>
        </div>
    )
}