import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocSegmentation() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    ControlNet
                </h1>
                <h2>Semantic Segmentation</h2>
                <p>Semantic Segmentation ControlNet is a flat color image where each object in the image is filled with a particular color. With Semantic Segmentation, colors are used to define and separate all the objects in the image.
                    </p>
                <p>The StableNetFusion app has drawing tools such as color pen, pencil and erasers, for you to draw shapes of the objects that you want to be generated by Stable Diffusion.
</p>
            </div>
        </div>
    )
}