import "./documentation.css"
import { Link } from "react-router-dom"
import DocSidePanel from "./DocSidePanel"
import reactRouterDom from "react-router-dom"

export default function DocControlNet() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    ControlNet
                </h1>
                <h2>About</h2>
                <p>ControlNet is a neural network structure to control diffusion models by adding extra conditions. There are many types of conditioning inputs (<Link to='/documentation/controlnet/scribble'>scribble</Link>, <Link to='/documentation/controlnet/canny-edge'>canny edge</Link>, <Link to='/documentation/controlnet/semantic-segmentation'>semantic segmentation</Link>, <Link to='/documentation/controlnet/depth-map'>depthmap</Link>, etc.) you can use to control a diffusion model. </p>

                <p>Typically ControlNet inputs take the form of processed images that are fed into the diffusion pipeline, along with the text prompt, and will help shape the outcome generated image.</p>

                <p>With ControlNets you won’t have to rely on words alone to guide the diffusion model. You can use drawing, photographs, and more.</p>

                <p>And the StableNetFusion app has build-in tools for you to create ControlNet sketches and preprocessed images so you won’t need to use a separate image editor.
                </p>
            </div>
        </div>
    )
}