import "./documentation.css"
import DocSidePanel from "./DocSidePanel"

export default function DocTextToImage() {
    return (
        <div className="doc">
            <DocSidePanel />
            <div className="doc-body">
                <h1>
                    Stable Diffusion
                </h1>
                <h2>Text-To-Image</h2>
                <p>Text-to-image is a Stable Diffusion task that allows you to submit text descriptions called prompts that will guide the diffusion model in generating an image.</p>

                <p>Prompts can be both positive and negative. And negative prompts indicate to the diffusion model what content to remove from an image during the diffusion process.
                </p>
            </div>
        </div>
    )
}